import { API_URLS } from 'api/urls';
import { makeDispatch } from 'redux/utils';

export const DELETE_CUMF = 'DELETE_CUMF';
export const FETCH_MEDIA_LIST = 'FETCH_MEDIA_LIST';


export const fetchMediaList = ({ url, params }) => (dispatch, getState) => {
  return makeDispatch({
    data: null,
    dispatch,
    method: 'GET',
    params,
    state: getState(),
    type: FETCH_MEDIA_LIST,
    url: url || API_URLS.media.list
  });
};