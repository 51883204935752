import React from 'react';
import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useUpdateCheck } from 'react-update-notification';

import CookieConsent from "react-cookie-consent";

import { useTranslation } from "react-i18next";

import { loadMe } from 'redux/actions/auth';

import 'assets/base.scss';

import { ScrollTop } from 'components/scroll';
import renderRoutes from 'router';

const NotificationContainer = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    interval: 24 * 60 * 60 * 1000, // 1day
  });
  console.log(status)

  if (status === 'checking' || status === 'current') {
    return null;
  }

  return (
    <div
      style={{
        position: 'absolute', left: '1rem', bottom: '4rem', zIndex: 9999
      }}
    >
      <Button
        variant="default"
        onClick={reloadPage}
        style={{
          maxWidth: '140px',
          backgroundImage: 'linear-gradient(to right, #fbc2eb 0%, #a6c1ee 51%, #fbc2eb 100%)',
        }}
      >
        Refresh to update the app
      </Button>
    </div>
  );
};

const App = ({
  user,
  loadMe
}) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    loadMe();
  }, [loadMe]);

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LdxFOMnAAAAADSJQzK3Tvafm90ZrPqNK03S87LW"
      language="ru"
      scriptProps={{
        async: true,
        appendTo: 'body',
      }}
    >
      <CookieConsent
        location="bottom"
        style={{ alignItems: 'center', background: "#2B373B" }}
        buttonStyle={{
          background: "#2B373B",
          color: "white",
          border: "white 1px solid"
        }}
        buttonText={t('accept')}
        expires={150}
      >
        <h3>Сайт использует файлы cookie</h3>
        <p>Сайт использует файлы cookie, которые позволяют узнавать вас и получать информацию о вашем пользовательском опыте.</p>
        <p>Разрешение на использование cookie необходимо для использования Сайта и его сервисов, включая заказ услуг. Посещая страницы сайта, мы уверены, что вы даете согласие на использование и хранение файлов cookie на вашем устройстве. Если согласны, продолжайте пользоваться сайтом. Если нет – установите специальные настройки в браузере или обратитесь в техподдержку.</p>
      </CookieConsent>
      <div className="content-wrapper">
        <NotificationContainer />
        {renderRoutes(user)}
        <ScrollTop />
      </div>
    </GoogleReCaptchaProvider>
  );
};

export default connect(
  (state) => ({
    user: state.user
  }),
  {
    loadMe,
  },
)(App);
