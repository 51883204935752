import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import browserHistory from 'router/history';
import { syncHistoryWithStore } from 'react-router-redux';

import 'assets/base.scss';
import App from 'App';
import "core/i18n";
import { store } from 'redux/store';

import reportWebVitals from './reportWebVitals';

const history = syncHistoryWithStore(browserHistory, store);

const root = ReactDOM.createRoot(document.getElementById("is-root"));

root.render(
  <React.StrictMode>
    <React.Suspense fallback="Loading...">
      <Provider store={store}>
        <BrowserRouter location={history.location} navigator={history}>
          <App />
        </BrowserRouter>
      </Provider>
    </React.Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
